@import "../../../styles/base";

/*#LoginPortal {
  margin-top: 30px;

  .button {
    font-size: 20px;
    padding: 20px 60px;
  }
}*/

#LoginPortal,
.resetMyPassword {
  .resetSuccessHeader {
    background: #36355a;
    height: 156px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resetSuccessIcon {
    width: 80px;
    height: 80px;
    border: 3px solid #aabee7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resetSuccessBody {
    min-height: 80px;
    padding: 40px;

    button {
      width: calc(100% - 25px);
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      line-height: 24px;
      outline: none;
      border-width: 0;
      border-radius: 4px;
      margin-top: 30px;
      overflow: hidden;
      transition: border-radius 0.2s ease 0s;
      padding: 20px 0;
      @extend %fontRoundBold;
      color: #ffffff;
      background: $successColor;
    }
  }
  .resetSuccessTitle {
    color: #36355a;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .resetSuccessText {
    color: #36355a;
    font-size: 18px;
  }

  .accessSystem {
    @extend %fontRoundBold;
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 35px;
  }

  .rulesPassword {
    display: flex;

    > .formItemPortal {
      margin-top: 50px;
    }

    > .validationContainer {
      margin-left: 20px;
      margin-top: 50px;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
    }

    > .validationContainer {
      color: $midnightBlue80;

      .policiesTitle {
        font-size: 18px;
        display: block;
        margin-bottom: 16px;
        font-weight: bold;
      }

      .policiesList {
        padding: 0 24px 0 18px;
        display: flex;
        flex-direction: column;
        max-width: 300px;
        align-items: flex-start;

        .policyItem {
          list-style-type: disc;
          margin-bottom: 8px;
          text-align: start;

          &.valid {
            color: green;
          }
        }
      }
    }
  }

  .formItemPortal {
    margin-bottom: 50px;

    .formRecaptcha {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    input[type="text"],
    input[type="password"] {
      width: calc(100% - 25px);
      height: 60px;
      border-radius: 4px;
      border: 2px solid #ffffff;
      padding: 8px 55px 5px 18px;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      background: #ffffff;
      margin-bottom: 10px;
      color: #909097;
      opacity: 0.7;
    }

    p {
      font-size: 14px;
      margin: 20px 0 0 5px;
      color: #ffffff;
      opacity: 0.8;
      cursor: pointer;
      @extend %fontRegular;
      text-align: left;
    }

    .textResetMyPassword {
      margin-left: 15px;
    }

    button {
      width: calc(100% - 25px);
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      line-height: 24px;
      outline: none;
      border-width: 0;
      border-radius: 4px;
      overflow: hidden;
      transition: border-radius 0.2s ease 0s;
      padding: 20px 0;
      @extend %fontRoundBold;
      color: #ffffff;
      background: $secondaryColor;
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      .formItemPortal {
        p {
          padding-bottom: 10px;
        }
      }

      .accessSystem {
        margin-top: 30px;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}
