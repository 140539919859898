@import "../../../../../styles/base";

.contentInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .left-Info {
    p {
      font-size: 13px;
      color: #7f808e;
      text-transform: uppercase;
      line-height: 22px;
      @extend %fontRoundBold;
    }
  }

  .right-info {
    p,
    .TagsProject {
      font-size: 14px;
      color: #000000;
      line-height: 22px;
      word-break: break-word;
      @extend %fontRegular;
    }
  }

  @media screen and (max-width: 768px) {
    .left-Info {
      width: 50%;
    }

    .right-info {
      width: 50%;
      text-align: right;
    }
  }
}
