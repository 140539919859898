@import "../../../styles/base.scss";

.ConfirmationBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  .ConfirmationBoxBackground {
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.7);
    z-index: 20;
  }

  .ConfirmationWindow {
    width: 100%;
    max-width: 625px;
    min-height: 200px;
    height: fit-content;
    background: #fff;
    position: fixed;
    border-radius: 4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 21;
    will-change: transform;
    transform: translateY(100vh);
    transition: 300ms cubic-bezier(0, 0.8, 0.8, 1);
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px,
      rgba(0, 0, 0, 0.12) 0px 6px 30px, rgba(0, 0, 0, 0.14) 0px 16px 24px;
    padding: 24px;

    &.largeWindow {
      max-width: 750px;
    }

    .confirmationTitle {
      margin: 9px 0 24px 0;
      @extend %fontRoundBold;
      color: #1e1f24;
    }

    .confirmationMessage {
      width: 100%;
      color: #6b6c78;
      font-weight: 100;
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 36px;

      strong {
        @extend %fontRoundBold;
      }
    }

    .confirmationButtons {
      display: flex;
      margin-top: auto;
      margin-left: auto;

      .simpleButton {
        margin-right: 12px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }

  &.active {
    .ConfirmationWindow {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 1024px) {
    .ConfirmationWindow {
      width: 100%;
      border-radius: 0;
      height: auto;
      overflow-y: scroll;

      .header {
        border-radius: 0;
      }

      .content {
        flex-grow: 1;
        max-height: 100vh;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .ConfirmationWindow {
      .header {
        h2 {
          font-size: 18px;
        }
      }

      .confirmationButtons {
        width: 100%;
        flex-direction: column;

        .simpleButton {
          margin-left: 0;
          font-size: 16px;
          width: 50%;
          padding: 16px;
          justify-content: center;

          span {
            margin: 0;
          }

          &.simpleButton:last-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}
