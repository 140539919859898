@import "../../../../styles/base.scss";

.changePassword {
  display: flex;
  padding: 32px 48px;
  margin-bottom: 64px;

  .validationContainer,
  .fieldsContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .validationContainer {
    color: $midnightBlue80;

    .policiesTitle {
      font-size: 18px;
      display: block;
      margin-bottom: 16px;
      font-weight: bold;
    }

    .policiesList {
      padding: 0 24px 0 18px;

      .policyItem {
        list-style-type: disc;
        margin-bottom: 8px;

        &.valid {
          color: green;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
