@import "../../../styles/base.scss";

#Channels {
  > .description {
    color: #36355a;
    font-size: 26px;
    width: 100%;
    text-align: center;
    margin: 48px 0 42px;
  }
}
