@import "../../../styles/base";

#HeaderPortal {
  background: $primaryColor;

  .containerHeader {
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .closedMenu {
      position: absolute;
      top: 20px;
      right: 15px;
      font-size: 28px;
      color: #ffffff;
      @extend %fontRegular;
    }

    h1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff1f2;
      font-size: 20px;
      @extend %fontLight;
      font-weight: 100;

      a {
        text-decoration: none;
        font-size: 20px;
        @extend %fontLight;
      }
    }

    svg {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;

    .containerHeader {
      width: 100%;
      flex-wrap: wrap;
      padding: 0;

      h1 {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #adafef;

        a {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;

          .scriptIcon {
            width: 100%;
            padding: 0;
            margin: 0;
            line-height: 1px;
          }
        }
      }
    }
  }
}