@import "../../../styles/base";

#navNavigationHeader {
  display: flex;
  align-items: center;

  .sliceItem {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d3d6fd;
    color: $colorGray;
    font-size: 14px;
    @extend %fontRoundBold;
    margin-right: 5px;
    box-sizing: border-box;
    border: 1px solid #d3d6fd;
  }

  a,
  .logout {
    color: $secondaryColor;
    margin: 0 10px;
    font-size: 16px;
    text-decoration: none;
    @extend %fontRoundBold;
    height: 85px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    border-top: 5px solid transparent;
    border-radius: 0;

    &.active {
      box-sizing: border-box;
      color: #ffffff;
      border-top: 5px solid $secondaryColor;
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      width: fit-content;
      z-index: 100000;
      padding: 0;

      a {
        border-radius: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 -10px;

        &.active {
          border-top: 5px solid transparent;
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    background: #24233d;
    padding-top: 30px;
    // position: absolute;
    // margin-left: -100%;

    a,
    .logout {
      display: block;
      width: 100%;
      height: auto;
      padding: 0;
      text-align: center;
      margin-bottom: 30px;
      font-size: 18px;
    }

    .logout {
      display: flex;
      justify-content: center;
    }
  }
}
