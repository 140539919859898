@import "../../../../../styles/base";

#Step1 {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    .contentButton {
      width: 100%;
      text-align: right;
    }

    .errorMsgTitle {
      display: flex;
      padding: 17px;
      gap: 12px;
      background: #ffdee4;
      border: 1px solid $errorColor;
      border-radius: 4px;
      margin-top: 10px;

      > p {
        color: $errorColor;
      }
    }

    .formTitle.MsgError {
      input {
        border: 1px solid $errorColor;
      }
    }

    .formItem {
      position: relative;

      .platform {
        width: 100%;
        display: flex;
        align-items: center;

        .radioOptionList {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;

          .radioText {
            position: inherit;
          }

          .radioButton {
            margin-right: 10px;
          }

          .radioOption {
            margin-right: 20px;
          }
        }
      }

      .timeProductionProject {
        width: 100%;
        display: flex;
        align-items: center;

        .radioInput {
          width: 100%;
          flex-direction: initial;
          align-items: center;
        }

        .radioOptionList {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: initial;

          .radioOption {
            width: 25%;
            display: flex;
            align-items: center;
            height: fit-content;

            .radioText {
              position: inherit;
            }
          }
        }
      }

      .footerSpan {
        position: absolute;
        bottom: -22px;
        color: #9395a3;
        font-size: 14px;

        strong {
          color: $secondaryColor;
        }
      }
    }
  }

  .react-tagsinput {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    transition: 300ms ease-in-out;
    font-size: 18px;
    z-index: 5;
    position: relative;

    & > span {
      height: 50px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
    }
  }

  .react-tagsinput--focused {
    // border-color: #a5d24a;
  }

  .react-tagsinput-tag {
    width: fit-content;
    background-color: #e4f8ff;
    border: 1px solid #aaf0fd;
    border-radius: 4px;
    word-break: break-all;
    height: 30px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 10px;
    justify-content: space-between;
    position: relative;

    a {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      min-width: 20px;
      max-width: 20px;
      color: #17b4e1;
      border-left: 1px solid #aaf0fd;
      cursor: pointer;
      border-radius: 0;
      padding: 0;
      margin-left: 10px;
      @extend %fontLight;

      &:before {
        content: "x";
      }
    }
  }

  /*.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 100%;
    color: #17b4e1;
    background: red;

    a {
      width: 100%;
      border: 1px solid red;
    }
  }*/

  /*.react-tagsinput-tag a::before {
    content: " ×";
  }*/

  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
  }
}

#Step2 {
  .contentTextArea {
    margin-bottom: 10px;
    position: relative;

    &.error {
      .textareaContainer {
        textarea {
          border: 1px solid $errorColor;
        }
      }
    }

    .errorLabel {
      position: absolute;
      bottom: -25px;
      right: 0;
      color: $errorColor;
      font-size: 12px;
      @extend %fontRegular;
    }
  }

  .titleRegisterDramaturgy {
    margin-bottom: 10px;
  }
}

.contentButton {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
}
