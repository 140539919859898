@import "../../../styles/base";

#Breadcrumb {
  min-height: 100px;
  width: 100%;
  background: $terciaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  .title {
    font-size: 28px;
    display: flex;
    align-items: center;
    color: #fff;
    @extend %fontRoundBold;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 28px;
      display: flex;
      align-items: center;
      color: #fff;
      @extend %fontRoundBold;
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      display: flex;
      flex-wrap: wrap;
      padding: 30px 0;
      justify-content: center;

      .button.large, a.large {
        padding: 5px 16px;
      }

      h2 {
        font-size: 24px;
      }

      p {
        padding-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      h2 {
        font-size: 20px;
      }
    }
  }
}
