@import "../../../styles/base.scss";

.alertBox {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  margin-bottom: 20px;
  background: #fff4e4;
  border: 1px solid #d2a55e;
  border-radius: 4px;
  position: relative;
  flex-wrap: wrap;

  .closedBox {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
  }

  ul {
    width: 100%;
    padding: 10px;
    margin-left: 50px;

    li {
      list-style-type: disc;
      color: #d2a55e;
      @extend %fontRoundBold
    }
  }

  p {
    color: #d2a55e;
    font-size: 14px;
  }

  svg {
    margin: 0 10px;
    transform: rotate(180deg);
  }

  &.error {
    background: #ffdee4;
    border: 1px solid #db1a33;

    ul {
      li {
        list-style-type: disc;
        color: #db1a33
      }
    }

    p {
      color: #db1a33;
    }
  }
}