@import "../../../../../styles/base.scss";

#EditSheetProject {
  margin-bottom: 50px;

  .stepCounter {
    .stepIcon {
      transform: rotate(90deg);
    }
  }

  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 700px;
    margin: 0 auto;
  }

  .contentH2AndSteps {
    width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    h2 {
      @extend %fontLight;
      font-size: 28px;
      color: $primaryColor;
    }
  }
}