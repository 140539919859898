@import "../../../../../styles/base.scss";

.infoCard {
  width: 100%;
  background-color: #daddfe;
  box-shadow: rgba(0, 0, 0, 0.12) 0 2px 2px;
  border-radius: 4px;
  margin-bottom: 36px;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
    border-bottom: 1px solid #c0c3f8;
    max-height: 77px;
    margin-bottom: 2px;

    &.termsOfUse {
      border-bottom: none;
    }

    .cardTitle {
      display: flex;
      align-items: center;
      line-height: 28px;
      font-size: 20px;
      color: #1e1f24;
    }

    .scriptBoxCaller {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 5px;
    }
  }

  .allAttachments {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #007eb5;
    @extend %fontRoundBold;
    margin-bottom: 0;
    padding: 24px 32px;
    border-top: 1px solid #c0c3f8;

    .scriptBoxCaller {
      position: relative;
      font-size: 16px;
    }
  }
}
