@import "../../../styles/base.scss";

#Footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 80px;

  img {
    margin: 20px 0 32px 0;
  }

  @media screen and (max-width: 1024px) {
    & {
      img {
        margin: 20px 0 15px 0;
      }
    }
  }
}
