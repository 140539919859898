@media print {
  * {
    text-shadow: none;
    filter: none;
    -ms-filter: none;
  }

  html {
    background-color: #fff;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.4em;
    overflow: visible;
    height: auto;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: #fff;

    .evaluationHeader {
      display: none;
    }

    #Header,
    #Breadcrumb,
    .conflictsFilter,
    .listInfo,
    #PaginationContent {
      display: none;
    }

    .tableSection {
      padding: 0;
      background-color: #fff;

      .tableContainer {
        .listInfo,
        .renderList {
          display: none;
        }

        .printList {
          display: block;

          .conflictsTable {
            margin: 0;
            display: block;

            .conflictItem {
              &.breakPage {
                display: block;
                break-after: always;
                page-break-after: always;
              }

              .conflictHeader,
              .conflict {
                border-bottom: 1px solid #ccc;
              }

              .conflictHeader {
                .itemArrow {
                  transform: rotate(90deg);
                }

                .removeButton {
                  display: none;
                }
              }

              .conflict {
                max-height: 1000px;
                margin-bottom: 1px;
                height: 150px;
                overflow: hidden;

                .conflictBox {
                  opacity: 1;
                  overflow: hidden;
                  padding-bottom: 10px;
                  max-height: 100%;

                  .productInfo {
                    .productInfoName {
                      font-size: 14px;
                    }

                    .productPeriod {
                      font-size: 12px;
                    }
                  }

                  .characterInfo {
                    max-height: 100%;

                    .characterName {
                      font-size: 14px;

                      .nickName {
                        font-size: 14px;
                      }
                    }

                    .characterDescription {
                      max-height: 100px;
                      overflow: hidden;

                      span {
                        font-size: 12px;
                      }
                    }
                  }

                  .productInfo {
                    .conflictedProducts {
                      min-height: 14px;
                      position: relative;
                      color: #43444e;
                      font-size: 14px;
                      margin-bottom: 2px;

                      .importanceMark {
                        position: absolute;
                        left: -15px;
                        margin-right: 14px;
                        margin-top: 4px;
                      }
                    }
                  }
                }

                .removeButton {
                  display: none;
                }

                .scriptBoxCaller {
                  display: none;
                }
              }

              .conflictsGraphic {
                overflow-y: visible;
                overflow-x: hidden;
                max-height: 10000px;
                padding: 24px 0 24px 24px;
              }
            }
          }
        }
      }
    }
  }
}

@page {
  margin: 0;
}
