.Steps {
  .formItem {
    .platform {
      width: 100%;
      display: flex;
      align-items: center;

      .radioOptionList {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;

        .radioButton {
          margin-right: 10px;
        }

        .radioOption {
          margin-right: 20px;
        }
      }
    }
  }

  .timeProduction {
    width: 100%;
    display: flex;
    align-items: center;

    .radioInput {
      width: 100%;
      flex-direction: initial;
      align-items: center;
    }

    .radioOptionList {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: initial;
    }
  }
}

.stepsCenter {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0 100px;
}