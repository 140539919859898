@import "../../../styles/base";

#titleH2 {
  width: 840px;
  text-align: center;
  font-size: 36px;
  color: #ffffff;
  padding: 140px 0 0;
  line-height: 50px;
  @extend %fontRoundBold;
  margin: 0 auto 50px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}