@import "../../../styles/base.scss";

.corporateData {
  #Breadcrumb {
    .container {
      .corporateName {
        color: #ffffff;
        font-size: 35px;
        @extend %fontRoundBold;
      }
    }
  }

  .contentTitleTag {
    .ProjectSheetTitle {
      margin-bottom: 24px;
    }

    .contentInfo:last-of-type {
      margin-bottom: 0;
    }
  }

  .mainArea {
    width: 100%;
    min-height: 100vh;
    margin: 30px 20px 0 0;

    .container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .leftInfo {
      width: calc(100% - 370px);
      flex: 1;
      margin-right: 45px;
      margin-bottom: 40px;
    }

    .rightInfo {
      width: 355px;
    }
  }

  .usersList {
    display: flex;
    flex-direction: column;
    width: 100%;

    .userInfoItem {
      display: flex;
      flex-direction: column;
      padding: 8px 30px 8px 20px;
      width: 100%;
      border-bottom: 1px solid #c0c3f8;
      color: #5c5c68;

      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      .mainInfo {
        @extend %fontRoundBold;
        color: #585864;
      }

      .info {
        padding: 4px 0;
      }

      &:last-of-type {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .presentationText {
    padding: 20px 30px 20px 20px;
    margin-bottom: 16px;
    color: #5c5c68;
    font-size: 14px;
    line-height: 1.5;
  }

  .passwordArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .ProjectSheetTitle {
      width: fit-content;
    }

    .simpleButton {
      width: fit-content;
    }
  }

  .InfoPaper .right-info {
    width: auto;
  }
}
