@import "../../../../../styles/base.scss";

.Financing {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 10px;
  box-sizing: border-box;

  .label {
    width: 60%;
    line-height: 20px;
    text-transform: uppercase;
    color: $midnightBlue60;
    font-size: 13px;
    @extend %fontRoundBold;
  }

  .value {
    width: 40%;
    font-size: 14px;
    color: $colorGray;
    text-align: right;
  }
}