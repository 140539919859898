@import "../../../../styles/base.scss";

.editPresentation {
  padding: 42px 32px;
  margin-bottom: 48px;

  .presentationLabel {
    display: block;
    font-size: 18px;
    margin-bottom: 16px;
    color: $midnightBlue80;
  }
}
