@import "../../../styles/base";

#WelCome {
  width: calc(100% - 400px);

  p {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 30px;
    line-height: 30px;
    text-align: left;
    @extend %fontRegular;

    a {
      font-size: 20px;
      color: $secondaryColor;
      text-decoration: none;
      margin: 0 5px 0 0;
      padding: 0;
    }

    span {
      color: $secondaryColor;
    }
  }

  @media screen and (max-width: 1024px) {
    & {

      .goRegister {
        font-size: 16px;

        a {
          font-size: 16px;
        }
      }

      p {
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        line-height: 20px;

        strong {
          display: block;
        }
      }
    }
  }
}