@import "../../../styles/base";

.itemsCounter {
  max-width: 1120px;
  width: 100%;
  margin: 16px auto 12px auto;
  color: #7f808e;
  font-size: 14px;
  @extend %fontRegular;
  font-weight: lighter;

  @media screen and (max-width: 640px) {
    padding: 0 16px;
  }
}
