@import "../../../../styles/base.scss";

.editAddress {
  padding: 42px 32px;
  margin-bottom: 48px;

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .formItem {
    margin-bottom: 0;

    .reactSelect {
      margin-bottom: 25px;
    }

    &.large {
      width: 100%;

      .fieldset {
        width: 100%;
      }
    }

    &.medium {
      .fieldset {
        width: 50%;
      }
    }
  }

  .formItemDouble {
    width: 100%;
    display: flex;

    .formItem,
    .fieldset {
      width: 100%;
    }

    .formItem:first-of-type {
      margin-right: 25px;
    }
  }

  @media screen and (max-width: 1024px) {
    .formItemDouble {
      flex-direction: column;

      .formItem:first-of-type {
        margin-right: 0;
      }
    }

    .formItem {
      &.medium {
        width: 100%;
      }
    }
  }
}
