@import "../../../styles/base.scss";

.uploadingProgressContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  min-height: 70px;
  border-radius: 5px;
  padding: 10px 24px;
  z-index: 1;
  overflow-x: hidden;
  margin-top: 25px;

  .progressBar {
    position: absolute;
    background-color: #dff1e8;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
    transition: 50ms ease-in-out;
  }

  .uploadInfo {
    display: flex;
    align-items: center;
    z-index: 4;
    margin-right: auto;

    .progressPercentage {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 38px;
      z-index: 4;
      color: #3aa970;
      font-weight: bold;
      margin-right: 12px;
    }

    .fileName {
      display: block;
      font-size: 20px;
      word-break: break-all;
      z-index: 4;
      margin-right: 90px;
    }
  }

  .uploadControls {
    display: flex;
    align-items: center;
    margin-left: auto;
    z-index: 4;

    .simpleButtonContainer {
      z-index: 4;
      margin-left: auto;

      .simpleButton {
        span {
          margin: 0 7px;
        }
      }
    }

    .resendButton {
      margin-right: 10px;
    }

    .removeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 38px;
      border: 1px solid #aabee799;
      border-radius: 5px;
      z-index: 4;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }

  &.canceled {
    border-bottom: 2px solid $errorColor;

    .uploadInfo {
      .fileName {
        color: $errorColor;
      }
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;

    .uploadInfo {
      margin-top: 5px;
      margin-bottom: 15px;

      .fileName {
        margin-right: 10px;
      }
    }

    .uploadControls {
      margin-bottom: 10px;
    }
  }
}
