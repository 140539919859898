@import "../../../styles/base.scss";

.asyncLoadingContainer {
  width: 42px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  margin-top: 2px;

  .asyncLoading {
    width: 17px;
    height: 17px;
    border: 2px solid #aaa;
    border-top-color: #000;
    border-radius: 50%;
    margin-right: 15px;
    animation: spin 0.35s infinite linear;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
