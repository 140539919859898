@import "../../../styles/base.scss";

#RegisterProduction {
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 50px;
  }

  .contentButton {
    width: 100%;
    text-align: right;
  }

  .titleRegisterProduction {
    color: #56557b;
    @extend %fontLight;
    padding: 60px 0 40px;
    font-size: 18px;
    line-height: 30px;
  }

  .step2Production {
    h2 {
      padding-bottom: 20px;
    }
  }

  .contentStepsProduction {
    width: 700px;
  }

  #Step3 {
    .formRecaptcha {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .containerRegulation {
      margin-bottom: 20px;

      .contentRegulation {
        width: 100%;
      }

      .headerRegulation {
        display: flex;
        align-items: center;
        background: #ffffff;
        height: 75px;
        font-size: 16px;
        @extend %fontRoundBold;
        color: #575863;

        .checkBox {
          margin: 0 5px 0 20px;
        }
      }
    }

    .formRecaptcha {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      .titleRegisterProduction {
        width: 90%;
        margin: 30px auto;
        padding: 0;
        line-height: 20px;
      }

      .contentStepsProduction {
        width: 100%;

        form {
          width: 90%;
          margin: 0 auto;

          .formItem {
            &.medium {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
