@import "../../../styles/base.scss";

#About {
  padding-bottom: 60px;
  .containerAbout {
    width: 920px;
    background: #ffffff;
    margin: 40px auto 60px;
    padding: 50px 60px 20px;

    p {
      color: $midnightBlue90;
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 28px;

      a {
        margin: 0;
        padding: 0;
        color: $secondaryColor;
        text-decoration: none;
      }
    }
  }
}
