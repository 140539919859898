@import "../../../styles/base.scss";

.Accordion {

  .space {
    height: 50px;
  }

  .contentAccordion {
    width: 100%;
    padding: 20px;
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-wrap: wrap;
    background: #f9f9ff;
    border-bottom: 1px solid #e2e4ff;

    .itemArrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #24233d;
      margin-right: 10px;
      margin-bottom: 5px;
      transition: 200ms ease-in-out;
    }

    p.title {
      font-size: 17px;
      @extend %fontRegular;
      color: $midnightBlue90;
    }

    .content {
      width: 100%;
      transition: .2s ease-in-out;
      display: none;
      opacity: 0;
      padding: 10px 20px;
      color: #000;
      line-height: 25px;

      a {
        margin: 0;
        padding: 0;
        color: $secondaryColor;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 100%;
          padding: 5px 0;
        }
      }

      p {
        padding: 15px;
      }
    }

    &.active {
      background: #ffffff;

      .itemArrow {
        transform: rotate(90deg);
      }

      .content {
        opacity: 1;
        // max-height: 1000px;
        display: block;
      }
    }
  }
}