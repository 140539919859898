@import "../../../../styles/base";

#Dramaturgy {
  padding-bottom: 50px;

  .subTitleRegisterProduction {
    font-size: 20px;
    color: #575863;
    @extend %fontRegular;
  }

  .container {
    width: 740px;
    margin: 0 auto;

    .containerSteps {
      margin-top: 30px;
    }

    .titleRegisterDramaturgy {
      padding: 40px 0 20px;
      @extend %fontLight;
      font-size: 26px;
      color: #43444e;

      span {
        color: #6b6c78;
        font-size: 15px;
        @extend %fontLight;
      }
    }

    .subTitleRegisterDramaturgy {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
      color: $colorGray;
    }
  }

  .contentButton {
    padding-top: 30px;
    text-align: right;
  }

  #Step3 {
    width: 100%;

    .fieldsetUpload {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;

      .uploadService {
        width: 100%;
      }

      label {
        font-size: 12px;
        color: #474274;
        width: 100%;
        text-transform: uppercase;
        @extend %fontRoundBold;
      }
    }
  }

  #Step4 {
    .contentRegisterVideos {
      position: relative;
      margin: 20px 0;
      padding: 30px 30px 20px 30px;
      background: #ffffff;

      .removeItem {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .formItemVideo {
        margin-bottom: 20px;

        label {
          font-size: 12px;
          letter-spacing: 1.2px;
          color: #514a81;
          text-transform: uppercase;
          @extend %fontRoundBold;
        }

        input {
          width: 100%;
          min-height: 50px;
          padding: 5px 10px;
          border-radius: 4px;
          border: none;
          margin-top: 10px;
          background-color: #f1f2ff;
        }
      }
    }

    .dashedBox {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed rgba(170, 190, 231, 0.5);
      height: 50px;
      margin: 30px 0 25px 0;

      span {
        display: block;
        text-align: center;
        font-size: 18px;
        color: #38a86e;
        @extend %fontRoundBold;
        cursor: pointer;
      }
    }
  }

  #Step5 {
    .contentFormItemCheckBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      h3 {
        width: 100%;
        font-size: 12px;
        text-transform: uppercase;
        @extend %fontRoundBold;
        color: #514a81;
        margin: 25px 0;
        letter-spacing: 1.2px;
      }

      .contentCheckBoxAndInputValue {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        justify-content: flex-start;

        .inputValue {
          display: flex;
          align-items: center;
          width: calc(100% - 330px);

          input {
            width: 200px;
            height: 40px;
            padding: 0 10px;
            border-radius: 4px;
            background-color: #fff;
            border: none;
            color: #999be0;
            margin-right: 15px;
            @extend %fontRegular;

            &::placeholder {
              @extend %fontRegular;
              color: #999be0;
            }
          }
        }

        .checkBoxItem {
          display: flex;
          align-items: center;
          width: 310px;
          color: #62636f;
          font-size: 16px;
          @extend %fontRegular;
        }
      }

      .estimates {
        width: 100%;
        margin: 50px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .medium {
          width: calc(50% - 20px);

          label {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #474274;
            width: 100%;
            text-transform: uppercase;
            @extend %fontRoundBold;
            margin-bottom: 10px;

            svg {
              margin-top: -5px;
              margin-left: 10px;
            }
          }

          input {
            width: 100%;
            height: 60px;
            padding: 0 10px;
            border-radius: 4px;
            background-color: #fff;
            border: none;
            color: #999be0;
            margin-right: 15px;
            @extend %fontRegular;

            &::placeholder {
              font-size: 14px;
              @extend %fontRegular;
              color: #999be0;
            }
          }
        }
      }
    }

    .containerRegulation {
      margin-bottom: 20px;

      .headerRegulation {
        display: flex;
        align-items: center;
        background: #ffffff;
        height: 75px;
        font-size: 16px;
        @extend %fontRoundBold;
        color: #575863;
        cursor: pointer;

        .checkBox {
          margin: 0 5px 0 20px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      max-width: 100% !important;
      padding: 0 5px;
    }

    .formItem {
      width: 100%;

      .reactSelect__menu {
        z-index: 99999;
      }

      > .footerSpan {
        position: relative !important;
      }
    }

    #Step5 {
      .contentCheckBoxAndInputValue {
        flex-direction: column;

        .inputValue {
          width: 100% !important;
          flex-direction: column;
          gap: 3px;
        }
      }

      .estimates {
        flex-direction: column;
      }
    }
  }
}

#Dramaturgy
  #Step5
  .contentFormItemCheckBox
  .contentCheckBoxAndInputValue
  .inputValue
  input:disabled {
  background-color: #f2f2f2;
}
