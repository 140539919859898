@import "../../../styles/base.scss";

.reorderButton {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  .reorderArrows {
    margin-right: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;

    svg:nth-child(1) {
      margin-bottom: 2px;
      transform: rotate(180deg);
    }

    svg:nth-child(2) {
      margin-top: 2px;
    }
  }

  .reorderText {
    text-transform: uppercase;
    color: $cyan50;
    font-size: 12px;
    margin-top: 3px;
    @extend %fontRoundBold;
  }
}
