@import "../../../styles/base.scss";

.registerCard {
  height: 244px;
  min-width: 196px;
  background-color: #fff;
  box-shadow: rgba(80, 29, 226, 0.07) 0px 10px 20px;
  border-radius: 8px;
  opacity: 0.3;
  padding: 20px;
  border: 1px solid #aabee799;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
  margin: 10px;
  flex: 1;

  .cardIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 52px 0 36px 0;
    border-radius: 16px;
  }

  .cardTitle {
    text-align: center;
    line-height: 20px;
    @extend %fontRoundBold;
    color: #43444e;
  }

  &.active {
    opacity: 1;
    cursor: pointer;
    pointer-events: all;
  }

  @media screen and (max-width: 1024px) {
    flex-basis: calc(50% - 20px);
    margin: 10px;
  }

  @media screen and (max-width: 640px) {
    min-width: 100%;
    height: 88px;
    flex-direction: row;
    margin: 10px 0;

    .cardIcon {
      margin: 0 20px 0 0;
    }
  }
}
