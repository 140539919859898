.CounterLetters {
  width: 100%;
  font-size: 12px;
  color: #a4a7b8;
  text-align: right;
  margin-top: -15px;
  font-weight: bold;

  .errorCount {
    color: red;
  }
}