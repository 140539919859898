@import "../../../../styles/base.scss";

.ListItem {
  li,
  a {
    color: #43444e;
    font-size: 14px;
    text-decoration: none;
    @extend %fontRegular;
  }

  .HeaderListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 15px;
    padding: 0 20px;

    li {
      font-size: 14px;
      @extend %fontRoundBold;
      color: $colorGray;

      &.date {
        display: flex;
        justify-content: center;
        text-align: center;
      }

      &.active {
        color: $secondaryColor;
        @extend %fontRoundBold;
      }
    }
  }

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: 20px;
    margin-bottom: 1px;

    .title {
      font-size: 16px;
      color: #000000;
      @extend %fontRegular;
      display: flex;
      align-items: center;
      padding-right: 50px;

      a {
        font-size: 16px;
        color: #000000;
        @extend %fontRegular;
        text-decoration: none;
        padding: 10px;
      }
    }
  }

  .title {
    width: calc(50% - 10px);
  }

  .channel {
    width: 23%;
    text-align: center;
  }

  .date {
    width: 23%;
    text-align: center;
  }

  .status {
    width: 23%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .action {
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .contentAction {
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-left: -15px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      .HeaderListItem {
        padding: 0;

        li {
          &.title,
          &.status {
            width: calc(48% - 50px);
            display: block;
          }

          &.status {
            margin-right: 15px;
          }
        }
      }

      .date {
        display: none !important;
      }
      .channel {
        display: none !important;
      }

      .items {
        padding: 10px;

        li {
          display: none;
          &.title,
          &.status {
            display: block;
            width: calc(30%);
          }

          &.title {
            font-size: 14px;
          }

          &.action {
            position: relative;
            display: flex;

            & > .contentAction {
              display: flex;
              justify-content: center;
              align-items: center;

              &:last-child {
                margin-left: 0;
              }

              svg {
                width: 20px;
                height: 20px;
                margin-left: -5px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    > .HeaderListItem {
      .date {
        display: none !important;
      }
      .channel {
        display: none !important;
      }
    }
  }
}
