@import "../../../../../styles/base.scss";

.ListAttachmentsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 30px;

  .contentListAttachmentsBox, .contentListAttachmentsBoxHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding-right: 20px;
    border-bottom: 1px solid #c0c3f8;

    .thumb {
      width: 155px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .contentTextAndAction {
      width: calc(100% - 180px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: $secondaryColor;
        @extend %fontRoundBold;
      }

      .action {
        width: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &.contentListAttachmentsBoxHeader {
      background: transparent;
      border-bottom: 0;
      margin: 10px 0 5px;

      .thumb {
        color: $colorGray;
        font-size: 14px;
        @extend %fontRoundBold;
      }

      .contentTextAndAction {
        p {
          font-size: 14px;
          color: $colorGray;
          text-transform: uppercase;
        }
      }
    }
  }
}