@import "../../../styles/base.scss";

#RegisterOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e2e4ff;
  z-index: 2;
  padding: 16px;
  overflow-y: scroll;

  .closeButton {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      display: block;
      width: 18px;
      height: 2px;
      background-color: #cacce4;
      position: absolute;
      transition: 400ms ease-in-out;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }

  span {
    text-align: center;

    &.optionsTitle {
      margin: 72px auto 14px auto;
      font-size: 35px;
      font-weight: 300;
      @extend %fontLight;
      color: rgb(30, 31, 36);
      letter-spacing: -0.35px;
    }

    &.optionsSubTitle {
      margin: 0 auto 26px auto;
      font-size: 20px;
      color: rgb(67, 68, 78);
      line-height: 24px;
    }
  }

  .cardsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 440px;
    width: 100%;
    height: fit-content;
  }

  @media screen and (max-width: 640px) {
    span {
      &.optionsTitle {
        font-size: 28px;
      }

      &.optionsSubTitle {
        font-size: 16px;
        margin-bottom: 16px;
      }
    }

    .cardsContainer {
      max-width: 100%;
    }
  }
}
