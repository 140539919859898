@import "../../../../styles/base.scss";

.editCorporateData {
  padding: 42px 32px;
  margin-bottom: 48px;

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .formItem {
    margin-bottom: 0;
    width: 100%;

    input {
      min-height: 60px;

      &:disabled {
        background: #f1f2ff;
        border: 1px solid #f1f2ff;
      }
    }
  }

  .formItemDouble {
    width: 100%;
    display: flex;

    .formItem {
      width: 100%;
    }

    .formItem:first-of-type {
      margin-right: 25px;
    }
  }

  .fieldset {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    .formItemDouble {
      flex-direction: column;

      .formItem:first-of-type {
        margin-right: 0;
      }
    }

    .formItem {
      &.medium {
        width: 100%;
      }
    }
  }
}
