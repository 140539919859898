#mainPortal {
  background-image: url("../images/background.png");
  background-size: cover;
  background-position: center top;
  position: relative;
  height: 130vh;

  .contentLoginAndWelcome {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;

    #WelCome {
      width: calc(100% - 500px);
    }

    #LoginPortal {
      width: 100%;
      max-width: 455px;
      margin: 0 auto;
    }
  }

  .footerPortal {
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 10px;
    position: absolute;
  }

  @media screen and (max-width: 1024px) {
    & {
      height: fit-content;

      #titleH2 {
        width: 90%;
        margin: 0 auto 20px auto;
        font-size: 20px;
        line-height: 28px;
        padding: 40px 0 0;
      }

      .contentLoginAndWelcome {
        flex-direction: column;
        padding-bottom: 150px;

        #WelCome {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    & {
      #titleH2 {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
