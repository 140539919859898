@import "../../../styles/base.scss";

.loadingCircleContainer {
  height: 641px;

  .loadingCircle {
    width: 55px;
    height: 55px;
    border: 6px solid $primaryColor;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: loadingSpin 750ms linear infinite;
    margin: 30vh auto 0;

    @keyframes loadingSpin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
