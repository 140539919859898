@import "../../../styles/base.scss";

.stepCounter {
  width: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .stepIcon {
    position: absolute;
    right: 55px;

    svg {
      circle {
        transition: 200ms ease-in;
      }
    }
  }
}
