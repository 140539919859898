@import "../../../styles/base.scss";

.tagStatus {
  width: fit-content;
  color: #ffffff;
  text-align: center;
  padding: 8px 20px;
  font-size: 12px;
  @extend %fontRoundBold;

  &.yellow { background: #fad058}
  &.red { background: #dc1330}
  &.blue {background: #3366ff}
  &.green {background: #009d69}

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    padding: 5px 15px;
  }
}