#HelpDesk {
  margin-top: 30px;

  p {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
  }

  /*@media screen and (max-width: 1024px) {
    & {
      margin-top: 20px;
    }
  }*/
}