@import "../../../../styles/base.scss";

#listChannels {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .channelList {
    display: flex;
    gap: 11px;
    flex-wrap: wrap;
    padding-bottom: 150px;

    .channelItem {
      background: #fff;
      width: 176px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;

      > img {
        max-height: 70px;
      }
    }
  }

  .modalChannel {
    margin-bottom: 150px;
    width: 768px;
    min-height: 380px;
    max-height: 550px;
    overflow: auto;
    position: fixed;
    background: #fff;
    padding: 40px;
    top: 15%;

    > img {
      margin-bottom: 25px;
      max-height: 70px;
    }

    > p {
      font-size: 16px;
      line-height: 24px;
      color: #36355a;
    }

    .closeButton {
      color: #24233d;
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }
}
