@import "../../../styles/base.scss";

#RoundBackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  animation: fadeIn 150ms ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
