@import "./reset.scss";
@import "./print.scss";
@import "./reactSelect.scss";

$primaryColor: #24233d;
$secondaryColor: #17b4e1;
$terciaryColor: #36345a;
$placeholderColor: #8586cc;
$successColor: #1e9f69;
$errorColor: #e00038;
$confirmationWarning: #ec1045;
$alertColorPrimary: #eeb65f;
$alertColorSecondary: #fff4e3;
$alertTextColor: #996b1b;
$colorGray: #6b6c78;

$midnightBlue0: #e2e4ff;
$midnightBlue5: #daddfe;
$midnightBlue10: #d2d5fd;
$midnightBlue20: #c0c3f8;
$midnightBlue30: #adafef;
$midnightBlue40: #999be0;
$midnightBlue50: #8586cc;
$midnightBlue60: #7070b3;
$midnightBlue70: #5c5b97;
$midnightBlue80: #484879;
$midnightBlue90: #36355a;
$midnightBlue100: #24233d;

$cyan0: #e4fbff;
$cyan5: #c7f6fe;
$cyan10: #aaf0fd;
$cyan20: #71e1f8;
$cyan30: #3ecdef;
$cyan40: #17b4e1;
$cyan50: #0098cd;
$cyan60: #007eb5;
$cyan70: #006199;
$cyan80: #00467c;
$cyan90: #002f5f;
$cyan100: #001d42;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  @extend %fontRegular;
  background-color: #e0e2ff;
  position: relative;
}

#root {
  height: 100%;
  position: relative;
}

.container {
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 1120px) {
    width: 90%;
  }
}

.containerFlex {
  display: flex;
  justify-content: center;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Globotipo Text";
  src: url("../assets/fonts/GloboRegular.ttf");
}

%fontRegular {
  font-family: "Globotipo Text", sans-serif;
}

@font-face {
  font-family: "Globotipo Text Light";
  src: url("../assets/fonts/GlobotipoTexto-Light.ttf");
}

%fontLight {
  font-family: "Globotipo Text Light", sans-serif;
}

@font-face {
  font-family: "Globotipo Text Bold";
  src: url("../assets/fonts/GlobotipoRounded-Bold.ttf");
}

%fontRoundBold {
  font-family: "Globotipo Text Bold", sans-serif;
}

.button, a {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  align-content: space-between;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  border-width: 0;
  border-radius: 4px;
  overflow: hidden;
  transition: border-radius 0.2s ease 0s;
  padding: 8px 16px;
  @extend %fontRoundBold;

  &.buttonGreen {
    background-color: #009c69;

    &:hover {
      background-color: #00885f;
    }
  }

  &.buttonRed {
    background: #ec1045;
  }

  &.buttonGray {
    background: gray;
  }

  &.buttonBlue {
    background: #17b4e1;

    &:hover {
      background: #3d99ce;
    }
  }

  &.buttonConfirm {
    background: #ec1045;
  }

  &.buttonCancel {
    border: 1px solid #c0c3f8;
    color: #7f808e;
    background-color: transparent;
  }

  &.large {
    padding: 12px 32px;
  }

  &.extraLarge {
    padding: 18px 24px;
    font-size: 18px;

    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }

  &.largeRound {
    padding: 18px 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
  }

  &.borderedButton {
    background-color: #fff;
    border: 1px solid #daddfe;
    border-radius: 34px;
    color: $cyan40;
    @extend %fontRegular;
    padding: 8px;
    transition: 250ms ease-in-out;

    &:hover {
      background-color: $cyan40;
      border-color: $cyan40;
      color: #fff;
    }
  }

  &.borderedButtonDark {
    background-color: transparent;
    border: 1px solid $midnightBlue60;
    border-radius: 34px;
    color: $midnightBlue60;
    @extend %fontRegular;
    padding: 8px;
    transition: 250ms ease-in-out;

    &:hover {
      background-color: $midnightBlue60;
      color: #fff;
    }
  }
}

.formItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;

  &.zindex1 {
    z-index: 1
  }

  &.zindex2 {
    z-index: 2
  }

  &.zindex3 {
    z-index: 3
  }

  &.zindex4 {
    z-index: 4
  }

  &.zindex5 {
    z-index: 5
  }

  &.zindex6 {
    z-index: 6
  }

  &.zindex7 {
    z-index: 7
  }

  &.zindex8 {
    z-index: 8
  }

  &.zindex9 {
    z-index: 9
  }

  &.zindex10 {
    z-index: 10
  }

  &.formCount {
    .count {
      font-size: 12px;
      width: 100%;
      text-align: right;
      padding-top: 5px;
      @extend %fontRoundBold
    }
  }

  textarea {
    width: 100%;
    height: 250px;
    background: #ffffff;
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    transition: 300ms ease-in-out;
    font-size: 18px;
    z-index: 5;
  }

  .errorLabel {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  label {
    color: $terciaryColor;
    @extend %fontRegular;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    transition: 300ms ease-in-out;
    font-size: 18px;
    z-index: 5;

    &:focus {
      border: 2px solid #17b4e1;
      box-shadow: 0 8px 36px rgba(123, 83, 239, 0.24);
    }
  }

  &.small {
    width: 33%;
  }

  &.verySmall {
    width: calc(33% - 20px);
  }

  &.adaptableSmall {
    width: 120px;
  }

  &.adaptable {
    width: calc(100% - 140px);
  }

  &.medium {
    width: calc(50% - 20px);
  }

  &.adaptableMedium {
    width: calc(100% - 280px);
  }

  &.large {
    width: 100%;
  }
}

.errorMessage {
  display: block;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.1em;
  margin-top: 8px;
  color: $errorColor;
  padding-left: 2px;
  font-weight: 500;
}

.backListProject {

  a {
    color: $cyan40;
    text-decoration: none;
    @extend %fontRoundBold;
    margin-left: 16px;
    white-space: nowrap;

    span {
      padding-right: 5px;
    }
  }
}

.contentBreadCrumbProject {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .contentLogoAndText {
    display: flex;
    align-items: center;
    position: relative;

    .typeIconReturner {
      width: 55px;
      height: 55px;
      margin-right: 20px;
    }

    p {
      color: #ffffff;
      font-size: 35px;
      @extend %fontRoundBold;
    }
  }
}

input {
  caret-color: $cyan50;
}

select {
  cursor: pointer;
}

.isLink {
  color: $secondaryColor;
  text-decoration: none;
}

.cardContent {
  display: flex;
  flex-wrap: wrap;

  span {
    display: block;
    padding: 20px 30px;
  }
}