@import "../../../styles/base.scss";

.contentRegulation {
  width: 920px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  padding: 30px 30px 20px;

  .markText {
    background: #fdfc07;
  }

  .link {
    color: $secondaryColor;
    margin: 0;
    padding: 0;
  }

  p {
    color: $midnightBlue90;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 28px;

    &.title {
      @extend %fontRoundBold;
    }
  }
}
