@import "../../../styles/base";

.ListAttachments {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .images {
    width: 100%;
    margin: 0 1px;
    box-sizing: border-box;
    padding: 20px;
    border-top: 1px solid #c0c3f8;

    &:first-child {
      border-top: none;
    }

    .headerFiles {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 10px;

      p {
        font-size: 12px;
        letter-spacing: 0.86px;
        color: #575863;
        text-transform: uppercase;
        @extend %fontRoundBold;
      }

      .date {
        font-size: 14px;
        line-height: 1.86;
        color: #575863;
      }
    }

    ul {
      li {
        @extend %fontRoundBold;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $secondaryColor;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
}