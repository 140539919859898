@import "../../../styles/base.scss";

.uploadedFileContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  margin: 25px 0 0 0;

  .fileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    padding: 0 24px;
    border-bottom: 1px solid #aabee74d;

    .fileName {
      display: block;
      font-size: 20px;
      word-break: break-all;
      z-index: 4;
      margin-right: 40px;
      padding: 16px 16px 16px 0;
    }

    .removeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      min-width: 46px;
      height: 38px;
      border: 1px solid #aabee799;
      border-radius: 5px;
      z-index: 4;
      cursor: pointer;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }

  .fileInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .fieldset {
      width: 42%;
    }

    .customDatepicker {
      width: 28%;
    }

    .numberInput {
      margin-left: 0;
      width: 20%;
    }

    &.onlyDatepicker {
      align-items: flex-start;

      .customDatepicker {
        width: 40%;
        min-width: 200px;
      }
    }
  }

  .chapter {
    flex-basis: 55%;
    display: flex;
    padding: 0;
    padding: 0 24px 24px 24px;

    .numberInput {
      flex-basis: 35%;
      margin-left: 0;
      min-width: 200px;
    }

    .fieldset {
      margin-top: 0;
      margin-left: 15px;
      flex: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .fileInfo {
      flex-direction: column;

      .fieldset {
        width: 100%;
        order: 0;
      }

      .numberInput {
        align-self: flex-start;
        order: 2;
        margin: 0;
        width: 200px;
      }

      .customDatepicker {
        order: 1;
        margin: 25px 0;
        width: 100%;
      }
    }

    .chapter {
      flex-direction: column;

      .numberInput {
        margin-bottom: 25px;
      }

      .fieldset {
        margin-left: 0;
      }
    }
  }
}
