@import "../../../styles/base.scss";

.uploadService {
  .formatInvalid {
    margin: 20px auto;
  }
  .dropzoneContainer {
    .rootDropzone {
      .dropzone {
        .dropzoneDashedBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 7px;
          border-radius: 12px;
          height: 205px;
          position: relative;
          border: 2px dashed #aabee7;

          span {
            color: #fff;
            font-size: 18px;
          }

          .dragTitle {
            font-weight: bold;
            color: #575863;
            font-size: 16px;
            margin-top: 12px;
            @extend %fontRoundBold;

            @media screen and (max-width: 768px) {
              display: none;
            }
          }

          .dragMaxTitle {
            @extend %fontLight;
            color: #7f808e;
            font-size: 12px;
            margin-top: 12px;
          }
        }
      }
    }
  }
}
