@import "../../../styles/base";

#RegisterSuccess {
  background-color: #e2e4ff;
  min-height: calc(100vh - 212px);
  width: 100%;

  .container {
    width: 900px;
  }

  .successIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #36355a;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 148px;

      .successIcon {
        border: 4px solid #484879;
        border-radius: 50%;
        width: 96px;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .textSuccessProduction {
    margin: 65px auto 20px;
    @extend %fontLight;
    text-align: center;
    font-weight: 300;
    font-size: 32px;
    color: rgb(87, 88, 99);
    display: block;

    a {
      color: rgb(87, 88, 99);
      @extend %fontLight;
      text-decoration: none;
      font-size: 32px;
      display: inline;

      strong {
        color: $secondaryColor;
        @extend %fontRoundBold;
        // margin: 0 -5px 0 10px;
      }
    }
  }

  .subTitleTextSuccessProduction {
    text-align: center;
    margin: 0 0 48px;
    font-size: 27px;
    @extend %fontLight;
    color: rgb(87, 88, 99);
    font-weight: 300;

    strong {
      @extend %fontRoundBold;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 640px) {
    h2 {
      font-size: 30px;
    }

    .buttons {
      flex-direction: column;

      div {
        margin: 0 auto 15px auto;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .container {
      width: 90% !important;
    }
  }
}

#Dramaturgy .container {
  width: 850px !important;

  #RegisterSuccess {
    .container {
      width: 900px;
    }
  }
}
