@import "../../../styles/base.scss";

.cookieApproval {
  background-color: #fff;
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px, rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 24px 0;
  z-index: 10;

  .approvalContainer {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .message {
      padding: 24px 24px 24px 0;
      max-width: 75%;
      color: $midnightBlue90;
      font-size: 18px;
      line-height: 28px;
      position: relative;
      display: inline;

      .scriptBoxCaller {
        position: relative;
        display: inline;
        color: $secondaryColor;
        font-size: 18px;
        line-height: 28px;
        text-decoration: underline;
      }
    }

    .simpleButton {
      height: fit-content;
      border-radius: 30px;
    }
  }

  @media screen and (max-width: 1200px) {
    .approvalContainer {
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .approvalContainer {
      flex-direction: column;

      .message {
        max-width: 100%;
        padding-top: 0;
      }

      .simpleButton {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.contentRegulation,
.container {
  width: 100%;

  @media screen and (max-width: 1024px) {
    height: 100%;
  }
}
