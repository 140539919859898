@import "../../../styles/base.scss";

#MyProject {
  min-height: 100vh;

  .emptyProject {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 50px 0;
    margin-top: 50px;

    p {
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
      @extend %fontRegular;
      color: $colorGray;
      font-size: 24px;
    }
  }

  a {
    text-decoration: none;
  }
}
