@import "../../../styles/base";

.simpleButton {
  z-index: 1;

  span {
    margin: 0 10px;

    @media screen and (max-width: 640px) {
      &.hideOnBreadcrumb {
        display: none;
      }
    }
  }

  &.rippleButton {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      top: 50%;
      left: 50%;
      transform: scale(1, 1) translate(-50%);
      transform-origin: 50% 50%;
      animation: rippleEffect 800ms ease-out;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      opacity: 0;
    }

    @keyframes rippleEffect {
      0% {
        transform: scale(0, 0);
        opacity: 1;
      }
      20% {
        transform: scale(25, 25);
        opacity: 1;
      }
      100% {
        opacity: 0;
        transform: scale(40, 40);
      }
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
