@import "../../../styles/base.scss";

.radioInput {
  display: flex;
  flex-direction: column;

  .label {
    display: block;
    color: $primaryColor;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .radioOptionList {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .radioOption {
      display: flex;
      align-items: center;
      height: 30px;
      margin-bottom: 8px;
      cursor: pointer;

      .radioButton {
        min-width: 26px;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #fff;
        margin-right: 12px;

        .radioDot {
          width: 8px;
          height: 8px;
          background-color: transparent;
          border-radius: 50%;
        }

        &.selectedRadio {
          border-color: $cyan40;

          .radioDot {
            background-color: $cyan40;
          }
        }
      }

      .radioText {
        font-size: 16px;
        color: #444444;
        @extend %fontRegular;
      }
    }
  }

  &.disabledRadio {
    opacity: 0.5;
    pointer-events: none;
  }
}
